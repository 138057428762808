<template>
  <div class="modal" v-if="showModal">
    <div class="modal-content">
      <button @click="closeModal">关闭</button>
      <h1>视频录制和音频录制</h1>
      <div>
        <!-- <button @click="startRecording" :disabled="recording">开始录制</button> -->
        <button @click="stopRecording" :disabled="!recording">停止录制</button>
      </div>
      <div>
        <!-- 实时视频预览 -->
        <video ref="videoPlayer" :autoplay="true" controls></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      recording: false,
      videoStream: null,
      audioStream: null,
      mediaRecorder: null,
      videoChunks: [],
      audioChunks: [],
    };
  },
  methods: {
    async openModal() {
      this.showModal = true
      this.$nextTick(async () => {
        await this.startRecording()
      })
    },
    closeModal() {
      this.showModal = false;
    },
    async startRecording() {
      try {
        this.videoStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });

        // 在视频元素上显示实时预览
        this.$refs.videoPlayer.srcObject = this.videoStream;

        this.mediaRecorder = new MediaRecorder(this.videoStream);
        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.videoChunks.push(event.data);
          }
        };

        this.mediaRecorder.onstop = () => {
          const videoBlob = new Blob(this.videoChunks, { type: 'video/mp4' });

          this.$refs.videoPlayer.srcObject = null; // 停止预览
          this.$refs.videoPlayer.src = URL.createObjectURL(videoBlob);

          this.videoChunks = [];
        };

        this.mediaRecorder.start();
        this.recording = true;
      } catch (error) {
        console.error('获取媒体设备失败：', error);
      }
    },
    stopRecording() {
      if (this.mediaRecorder && this.recording) {
        this.mediaRecorder.stop();
        this.videoStream.getTracks().forEach((track) => track.stop());
        this.recording = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  right: 0;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}
</style>
