<template>
  <div class="penrecord_wrap page template-page">
    <div v-loading.fullscreen.lock="loading" class="content" flex="main:justify">
      <div v-if="!pdfDownload" ref="leftPage" class="flex_1 m-r-20 b-r p-r-20 p-t-10 cardBg left-page">
        <avue-form ref="form" v-model="formData" :option="option" :upload-before="uploadBefore"
          :upload-after="uploadAfter">
          <template slot="time" slot-scope="{ value, column, size, disabled }">
            <el-input-number style="width:100%" v-model="formData.time" :step="1" :min="1"
              step-strictly></el-input-number>
          </template>
          <template slot="yhxxsHeader" slot-scope="{ column }">
            <div class="avue-group__header">
              <i :class="column.icon" class="avue-group__icon"></i>
              <h1 class="avue-group__title">{{ column.label }}</h1>
              <el-upload :action="cardUploadUrl" :headers="headers" :show-file-list="false"
                :before-upload="() => (loading = true)" :on-success="handleOnSuccess">
                <el-button size="mini" type="success" plain style="margin-left: 8px">上传身份证图片</el-button>
              </el-upload>
            </div>
          </template>

          <template slot="reInquirytorSignature" slot-scope="scope">
            <div flex="cross:center main:justify">
              <img v-if="scope.value" class="flex_1" :src="scope.value" alt="" height="32" />
              <el-link class="margin_left_10" type="primary" @click="signatureVisible = true">签名</el-link>
            </div>
          </template>
          <template slot="imgUrlType" slot-scope="{ file }">
            <div class="video-wrap">
              <div flex="main:justify cross:center">
                <video :ref="'video' + file.uid" width="250" height="56" controls="" name="media">
                  <source :src="file.url + `?date=${new Date().getTime()}`" type="audio/flac" />
                </video>
                <div flex>
                  <el-button plain size="mini" type="primary" @click="videoDiscern(file.url)">转文字</el-button>
                  <el-button plain size="mini" type="danger" @click="handleDeleteVideo(file.uid)">删除</el-button>
                </div>
              </div>
            </div>
          </template>
          <template slot="uploadBtn" slot-scope="{}">
            <div flex>
              <el-button size="small" type="primary" @click="handleVideo">全程录像 </el-button>
              <!-- <el-button size="mini" type="primary" :disabled="!isPad" @click="toApp('com.huawei.soundrecorder')">全程录音 -->
              <!-- </el-button> -->
            </div>
          </template>
          <template slot="content" slot-scope="{}">
            <div>
              <mavon-editor ref="mavonEditor" v-model="formData.content" :toolbars="toolbars" :subfield="false"
                @change="editorChange" />
            </div>
          </template>
        </avue-form>
      </div>
      <PenrecordTemplate class="flex_1 template" :formData="formData" :pdfDownload="pdfDownload" />
    </div>
    <DownloadBtn class="download-btn" :pdfDownload="pdfDownload" title="询问笔录" :formData="formData"
      :moduleName="currentModule.moduleName" @saveData="submitFormBackBefore" />
    <FooterBtn class="footer" :pdfDownload="pdfDownload" :submitDis="false" @submit="submitForm" @cancel="handleCancel" />
    <SignatureDialog v-model="signatureVisible" @submit="generate" />
    <ScreenRecording ref="screenRecordingRef" />
    <el-card v-show="videoDialog" class="left-dialog">
      <div v-for="(videoText, index) in currentVideoTextArr" :key="index">
        <div class="item question" @click="copyText(videoText)">
          <p>
            <span>{{ videoText }}</span>
          </p>
        </div>
      </div>
      <p class="end-line">- - - - - - - - 我是有底线的 - - - - - - - - -</p>
      <div class="footer">
        <el-button size="mini" class="button" @click="videoDialog = false">隐藏弹框</el-button>
      </div>
    </el-card>
    <div v-show="questionsList.length && !pdfDownload" class="right-dialog" :class="{ 'put-away': isPutAway }">
      <el-card class="content">
        <el-collapse v-model="formData.activeCollapse" accordion>
          <el-collapse-item v-for="(item, i) in questionsList" :key="item.templateId" :title="item.name"
            :name="item.templateId">
            <div v-for="(question, index) in item.archivesQuestionsList" :key="question.questionId">
              <div class="item question" :class="isQuestionActive(question) ? 'active' : ''"
                @click="assignmentQuestion(question, i + '-' + index)">
                <p>
                  <span>问：{{ question.question }}</span>
                </p>
                <video v-if="question.videoUrl" width="250" height="56" controls="" name="media">
                  <source :src="question.videoUrl" />
                </video>
              </div>
              <div class="item answer" @click="assignmentAnswer(question)">答：{{ question.answer }}</div>
            </div>
            <!-- <p class="end-line">- - - - - - - - 我是有底线的 - - - - - - - - -</p> -->
          </el-collapse-item>
        </el-collapse>
      </el-card>
      <div class="hide-box" @click="putAwayRight">
        <i :class="isPutAway ? 'el-icon-caret-left' : 'el-icon-caret-right'"></i>
      </div>
    </div>
  </div>
</template>

<script>
import DataFlow from '@/mixins/DataFlow'
import { getUser as GetUser } from '@/api/system/user'
import { findStrIndex, checkType } from '@/utils/index';
import { getToken } from '@/utils/auth'
import { pickerOptions } from '@/const/avue'
import FooterBtn from '@/components/FooterBtn'
import SignatureDialog from '@/components/SignatureDialog'
import DownloadBtn from '@/components/DownloadBtn'
import PenrecordTemplate from '@/components/Template/penrecord.vue'
import ScreenRecording from '@/components/ScreenRecording/index.vue'
import { INQUIRYGO_TYPE, INQUIRYGO_NAMES, INQUIRYGO_LIST, INQUIRYSEX_TYPE, INQUIRYSEX_NAMES, INQUIRYSEX_LIST, INQUIRYCARD_TYPE, INQUIRYCARD_LIST } from '@/const/archives/penrecord.js'
export default {
  mixins: [DataFlow],
  components: {
    FooterBtn, SignatureDialog, DownloadBtn, PenrecordTemplate, ScreenRecording
  },
  data(vm) {
    return {
      INQUIRYGO_TYPE,
      INQUIRYGO_NAMES,
      INQUIRYSEX_NAMES,
      pdfShow: true,
      obj: {
        width: 100,
        height: 100,
        left: 100,
        top: 100
      },
      signatureVisible: false,
      questionsList: [],
      currentVideoItem: {
        archivesQuestionsList: []
      },
      questionDialog: false,
      questionList: [],
      isPutAway: false,
      classKey: '',
      videoDialog: false,
      currentVideoTextArr: [],
      videoList: [],
      currentQuestion: null,
      toolbars: {
        // bold: true, // 粗体
        // italic: true, // 斜体
        // header: true, // 标题
        // underline: true, // 下划线
        // mark: true, // 标记
        // superscript: true, // 上角标
        // quote: true, // 引用
        // ol: true, // 有序列表
        readmodel: false, // 沉浸式阅读
        defaultOpen: 'edit',
        // link: true, // 链接
        // imagelink: true, // 图片链接
        help: true, // 帮助
        // code: true, // code
        subfield: true, // 是否需要分栏
        fullscreen: true, // 全屏编辑
        undo: true, // 上一步
        trash: true // 清空
        // save: true, // 保存（触发events中的save事件）
        // navigation: false // 导航目录
      },
      currentIndex: '',
      isDownload: false,
      userInfo: {}
    };
  },
  computed: {
    option() {
      return {
        submitBtn: false,
        emptyBtn: false,
        group: [
          {
            label: '询问人',
            prop: 'jbxx',
            icon: 'el-icon-edit-outline',
            arrow: false,
            column: [
              {
                labelTip: '自定义添加的请以 [ 工作单位-人名 ] 的格式来命名',
                clearable: false,
                span: 24,
                label: '询问人',
                prop: 'inquirytorName',
                type: 'select',
                filterable: true,
                allowCreate: true,
                multiple: true,
                virtualize: true,
                dicUrl: '/system/user/list?isDic=true',
                dicHeaders: this.headers,
                props: {
                  res: 'rows',
                  label: 'computedLabel',
                  value: 'userId'
                },
                dicFormatter: data => {
                  data.rows.forEach(ele => {
                    ele.computedLabel = `${ele.dept.deptName}-${ele.nickName}`;
                    ele.desc = ele.dept.deptName
                  });
                  return data.rows;
                },
                dataType: 'number',
                value: this.formData.inquirytorName,
                change: e => {
                  const { dic, value, item } = e
                  let inquirytor = '', inquirytorOrg = '';
                  if (dic.length > 0) {
                    dic.forEach(item => {
                      if (value.includes(item.userId)) {
                        if (inquirytor == '') {
                          inquirytor = item.nickName
                          inquirytorOrg = item.dept.deptName
                        } else {
                          inquirytor += ' ' + item.nickName
                          inquirytorOrg += ' ' + item.dept.deptName
                        }

                      }
                    })
                    const inquirytorOrgArr = [...new Set(inquirytorOrg.split(' '))]
                    inquirytorOrg = inquirytorOrgArr.join(' ')
                    this.formData.inquirytorOrg = inquirytorOrg
                    this.formData.inquirytor = inquirytor
                  }
                }
              }
            ]
          },
          {
            label: '记录人',
            prop: 'tksq',
            icon: 'el-icon-view',
            arrow: false,
            column: [
              {
                clearable: false,
                span: 24,
                label: '记录人',
                prop: 'recordtorName',
                type: 'select',
                filterable: true,
                virtualize: true,
                dicUrl: '/system/user/list?isDic=true',
                dicHeaders: this.headers,
                name: 'inquestPersons',
                props: {
                  res: 'rows',
                  label: 'computedLabel',
                  value: 'userId',
                },
                dataType: 'number',
                dicFormatter: data => {
                  data.rows.forEach(ele => {
                    ele.computedLabel = `${ele.dept.deptName}-${ele.nickName}`;
                    ele.desc = ele.dept.deptName
                  });
                  return data.rows;
                },
                change: (e) => {
                  this.formData.recordtorOrg = e?.item?.dept?.deptName
                  this.formData.recordtor = e?.item?.nickName
                }
              }
            ]
          },
          {
            label: '被询问人',
            prop: 'yhxxs',
            icon: 'el-icon-edit-outline',
            arrow: false,
            column: [
              {
                label: '被询问人',
                prop: 'reInquirytorName',
                rules: [
                  {
                    required: true,
                    message: "请输入被询问人",
                    trigger: "blur"
                  }
                ],
              },
              {
                label: '性别',
                prop: 'reInquirytorSex',
                type: 'select',
                dataType: 'number',
                dicData: INQUIRYSEX_LIST
              },
              {
                label: '年龄',
                prop: 'reInquirytorAge'
              },
              {
                label: '人大代表',
                prop: 'reInquirytorIsNpc',
                type: 'select',
                clearable: false,
                dicData: [
                  { value: '0', label: '否' },
                  { value: '1', label: '是' }
                ]
              },
              {
                label: '出生日期',
                prop: 'reInquirytorBirth',
                type: 'date',
                format: 'yyyy年MM月dd日',
                valueFormat: 'yyyy-MM-dd',
                editable: false,
                span: 24
              },
              {
                label: '证件类型',
                prop: 'reInquirytorCardType',
                type: 'select',
                dataType: 'number',
                dicData: INQUIRYCARD_LIST,
                span: 24
              },
              {
                label: '证件号码',
                prop: 'reInquirytorCardNum',
                span: 24,
                rules: [
                  {
                    required: true,
                    message: "请输入证件号码",
                    trigger: "blur"
                  }
                ],
              },

              {
                label: '联系电话',
                prop: 'reInquirytorPhone',
                span: 24
              },
              {
                label: '现住址',
                prop: 'reInquirytorAddressNow',
                span: 24
              },
              {
                label: '户籍',
                prop: 'reInquirytorAddressOld',
                span: 24
              },
              // 到达方式 移到签名一起
              {
                label: '到达时间',
                prop: 'reInquirytorStartTime',
                type: 'datetime',
                format: 'yyyy年MM月dd日 HH时mm分',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                pickerOptions,
                editable: false,
                focus: this.focus,
                span: 24
              },
              {
                label: '离开时间',
                prop: 'reInquirytorEndTime',
                type: 'datetime',
                format: 'yyyy年MM月dd日 HH时mm分',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                pickerOptions,
                editable: false,
                focus: this.focus,
                span: 24
              },
              {
                label: '到达方式',
                prop: 'reInquirytorGoType',
                type: 'select',
                clearable: false,
                dataType: 'number',
                dicData: INQUIRYGO_LIST
              },
              {
                label: '本人签名',
                prop: 'reInquirytorSignature',
                value: ''
              },
              {
                label: "录音",
                prop: "imgUrl",
                type: "upload",
                loadText: "附件上传中，请稍等",
                span: 24,
                labelTip: "上传录音，推荐用60秒",
                action: "/common/upload?isDic=true",
                headers: this.headers,
                accept: 'audio/*',
                dataType: 'string',
                propsHttp: {
                  res: "data"
                }
              },
              {
                label: '',
                prop: 'uploadBtn'
              },
              {
                span: 24,
                label: '询问对象',
                prop: 'archivesQuestionsPerson',
                type: 'select',
                props: {
                  label: 'dictLabel',
                  value: 'dictValue'
                },
                dicUrl: '/system/dict/data/type/person_type?isDic=true',
                dicHeaders: this.headers,
                change: ({ value }) => {
                  value && this.getQuestionsList(value);
                }
              },
              {
                label: '笔录内容',
                placeholder: '上传录音后，可识别成文字',
                prop: 'content',
                type: 'mavon-editor',
                span: 24,
                value: ''
              }
            ]
          }
        ],
        column: [
          {
            label: '询问次数',
            prop: 'time',
            span: 24,
            type: 'input-number',
            value: 0,
            controlsPosition: 'left',
            min: 0,
            step: 1,
            stepStrictly: true,
          },
          {
            label: '开始时间',
            prop: 'startTime',
            type: 'datetime',
            format: 'yyyy年MM月dd日 HH时mm分',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            value: (this?.currentRow?.archives && this?.currentRow?.archives?.fireTime) || this.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
            pickerOptions,
            editable: false,
            focus: this.focus,
            span: 24
          },
          {
            label: '结束时间',
            prop: 'endTime',
            type: 'datetime',
            format: 'yyyy年MM月dd日 HH时mm分',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            value: (this?.currentRow?.archives && this?.currentRow?.archives?.fireTime) || this.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
            pickerOptions,
            editable: false,
            focus: this.focus,
            span: 24,
            disabled: true
          },
          {
            label: '案发地点',
            prop: 'address',
            type: 'map',
            span: 24,
            // 高德初始化参数
            params: {
              zoom: 10
            },
            dataType: 'string'
          }
        ]
      };
    },
    cardUploadUrl() {
      return this.baseUrl + '/archives/notice/discern';
    },
    headers() {
      return {
        Authorization: 'Bearer ' + getToken()
      };
    },

    isPad() {
      return window.innerWidth <= 1200;
    },
    isQuestionActive() {
      return (question) => {
        let findItem = this.questionList.find(item => item.questionId == question.questionId && item.temId == question.temId)
        if (findItem && question.temId == findItem.temId) {
          return findItem
        }
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      Object.keys(this.formData).map(key => {
        this.$watch(
          `formData.${key}`,
          () => {
            this.classKey = key;
            if (this.$refs[key]) {
              this.$refs[key].scrollIntoView({
                block: 'start',
                behavior: 'smooth'
              });
            } GetUser
          },
          { deep: true }
        );
      });
    });
  },
  methods: {
    async beforeHandleData(newVal) {
      this.userInfo = (await GetUser(this.$route.query.userId)).data
    },
    afterHandleData(formData) {
      this.questionList = formData.questionList || this.questionList;
      this.currentVideoItem = formData.currentVideoItem || this.currentVideoItem;
      setTimeout(() => {
        this.classKey = '';
        if (!this.pdfDownload) {
          this.$refs.leftPage.scrollTop = 0;
        }
      });
    },
    handleVideo() {
      this.$refs.screenRecordingRef.openModal()
    },
    getQuestionsList(personType) {
      fetch(`${this.baseUrl}/archives/tem/list?type=0&personType=${personType}`, {
        method: 'GET',
        headers: {
          ...this.headers,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        mode: 'cors'
      })
        .then(e => e.json())
        .then(res => {
          if (res.code == 200) {
            this.questionsList = res.rows.map(tem => {
              return {
                label: tem.name,
                value: tem.templateId,
                ...tem
              };
            });
          }
        });
    },
    async questionChange(e) {
      this.formData.archivesQuestionsList = e;
      if (e) {
        if (this.questionsList.length) {
          this.currentVideoItem = this.questionsList.find(item => item.templateId == e);
        }
      }
      this.formData.questionBtnShow = !!e;
    },
    // 笔录填充
    async assignmentQuestion(item, i) {
      this.loading = true;
      const el = this.$refs.mavonEditor.$children[2].$refs.vTextarea;
      let temItem = this.questionList.find(tem => tem.questionId == item.questionId && tem.temId == item.temId)
      if (temItem) {
        const arr = this.questionList;
        arr.forEach((tem, index) => {
          if (tem.questionId == item.questionId) {
            const start = findStrIndex(this.formData.content, '问：', index);
            const end = findStrIndex(this.formData.content, '问：', index + 1);
            const startIndex = start > 0 ? start : 0;
            const endIndex = end > 0 ? end : this.formData.content.length;
            this.formData.content.substring(start, end);
            this.formData.content = this.formData.content.replace(this.formData.content.substring(startIndex != 0 ? startIndex - 1 : startIndex, endIndex), '');
            this.questionList.splice(index, 1);
          }
        });
      } else {
        this.questionList.push({
          questionId: item.questionId,
          temId: item.temId,
        });
        this.formData.content += `${this.formData.content != '' ? '\n' : ''}` + '问：' + item.question.trim() + '\n答：';
      }
      el.focus();
      this.loading = false;
      this.$nextTick(() => {
        el.scrollTop = el.scrollHeight;
      });
    },
    assignmentAnswer(item) {
      const el = this.$refs.mavonEditor.$children[2].$refs.vTextarea;
      this.formData.content += item.answer;
      el.focus();
      this.$nextTick(() => {
        el.scrollTop = el.scrollHeight;
      });
    },
    putAwayRight() {
      this.isPutAway = !this.isPutAway;
    },
    async videoDiscern(url) {
      this.loading = true;
      const res = await fetch(`${this.baseUrl}/archives/notice/videoDiscern`, {
        method: 'post',
        headers: this.headers,
        body: url
      })
        .then(e => e.json())
        .catch(() => (this.loading = false));
      this.loading = false;
      this.videoDialog = true;
      this.currentVideoTextArr.push(res.data.result.join());
    },
    handleDeleteVideo(uid) {
      let imgUrlArr = this.formData.imgUrl.split(',') || []
      const index = imgUrlArr.findIndex(item => item.uid === uid);
      imgUrlArr.splice(index, 1);
      this.formData.imgUrl = imgUrlArr.join(',')
      this.currentVideoTextArr.splice(index, 1);
    },
    copyText(videoText) {
      this.$Clipboard({
        text: videoText
      })
        .then(() => {
          this.$message.success('复制成功');
        })
        .catch(() => {
          this.$message.error('复制失败');
        });
    },
    editorChange(value, render) {
      this.$set(this.formData, 'contentRender', render);
    },
    toApp(name) {
      window.uni.postMessage({ data: { action: 'openApp', name: name } });
    },
    videoFormatDate(url) { },
    uploadBefore(file, done, loading) {
      // const isLt50M = file.size / 1024 / 1024 < 50

      // if (!isLt50M) {
      //   this.$message.error('上传的文件大小超出限制的文件大小！允许的文件最大大小是：50MB！')
      //   return loading()
      // }
      if (file.type == '') {
        return
      }

      const isFileType = file.type.split('/')[0] == 'audio'
      if (!isFileType) {
        this.$message.error(`上传附件只能是音频格式!`);
        return loading()
      }
      isFileType ? done() : loading()
    },
    uploadAfter(file, done, loading) {
      done();
    },
    focus() {
      this.$refs.form.$nextTick(() => {
        const inputs = document.querySelectorAll('.el-date-picker__editor-wrap input');
        [...inputs].forEach(item => {
          item.setAttribute('readonly', true);
        });
      });
    },
    handleOnSuccess(res) {
      if (!res.data.words_result_num) {
        this.$message.warning('无法识别，请上传清晰的证件照片');
      } else {
        this.$message.success('识别成功');
        const {
          姓名: { words: reInquirytorName },
          性别: { words: reInquirytorSex },
          出生: { words: reInquirytorBirth },
          公民身份号码: { words: reInquirytorCardNum },
          住址: { words: reInquirytorAddressOld }
        } = res.data.words_result;
        const idcardType = res?.data?.idcard_number_type;
        const sexMappings = {
          [INQUIRYSEX_NAMES[INQUIRYSEX_TYPE.MALE]]: INQUIRYSEX_TYPE.MALE,
          [INQUIRYSEX_NAMES[INQUIRYSEX_TYPE.FEMALE]]: INQUIRYSEX_TYPE.FEMALE,
        };
        const idcardTypeMappings = {
          1: INQUIRYCARD_TYPE.IDCARD,
          0: INQUIRYCARD_TYPE.RESIDENCEPERMIT,
        };
        const day = `${reInquirytorBirth.slice(0, 4)}, ${parseInt(reInquirytorBirth.slice(4, 6))}, ${reInquirytorBirth.slice(6, 8)}`
        Object.assign(this.formData, {
          reInquirytorName,
          reInquirytorSex: sexMappings[reInquirytorSex] !== undefined ? sexMappings[reInquirytorSex] : '',
          reInquirytorBirth: this.parseTime(new Date(day)),
          reInquirytorCardType: idcardTypeMappings[idcardType] !== undefined ? idcardTypeMappings[idcardType] : '',
          reInquirytorCardNum,
          reInquirytorAddressOld,
          reInquirytorAge: new Date().getFullYear() - new Date(day).getFullYear()
        });
      }
      this.loading = false;
    },
    generate(base64) {
      this.formData.reInquirytorSignature = base64
    },
    initForm() {
      const archives = this.currentRow?.archives || {};
      console.log(this.archivesData);
      const { doPerson, doPersonName, doOtherPerson, doOtherPersonName } = this.archivesData
      let initForm = {
        // title: `${this.$route.query.nickName}-${this.parseTime(new Date(), '{y}年{m}月{d}日')}`,
        remark: '',
        time: 1,
        startTime: this.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
        endTime: this.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
        address: archives.fireAddress || '',
        inquirytorName: `${doPerson}${doOtherPerson ? ',' + doOtherPerson : ''}`,
        inquirytor: doPersonName + ' ' + doOtherPersonName,
        inquirytorOrg: '',
        recordtorName: archives.doPerson,
        recordtor: '',
        recordtorOrg: doPersonName,
        reInquirytorName: '',
        reInquirytorSex: '',
        reInquirytorAge: '',
        reInquirytorBirth: '',
        reInquirytorCardType: '',
        reInquirytorCardNum: '',
        reInquirytorIsNpc: '',
        reInquirytorPhone: '',
        reInquirytorAddressNow: '',
        reInquirytorAddressOld: '',
        reInquirytorGoType: INQUIRYGO_TYPE.VERBALSUMMONS,
        reInquirytorStartTime: this.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
        reInquirytorEndTime: this.parseTime(new Date(), '{y}-{m}-{d} {h}:{i}:{s}'),
        reInquirytorSignature: '',
        imgUrl: '',
        content: '',
        questionBtnShow: false,
        archivesQuestionsList: '',
        activeCollapse: ''
      };
      return initForm
    },
    submitFormBefore() {
      this.formData.questionList = this.questionList;
      this.formData.currentVideoItem = this.currentVideoItem;
      this.formData.endTime = new Date();
      if (this.formData.address && checkType(this.formData.address) == 'array') {
        this.formData.address = this.formData.address.join(',')
      }
      let imgUrlArr = this.formData.imgUrl.split(',') || []
      this.formData.videoFileList = imgUrlArr.map(item => {
        return item;
      });
    },
    savePdf() {
      const { reInquirytorName } = this.formData;
      const title = reInquirytorName + '-' + this.parseTime(new Date().getTime(), '{y}年{m}月{d}日{h}时{i}分');
      this.uploadPdf(true, title + '.pdf', false);
    },
    downloadPdf() {
      if (this.isPad) {
        if (!this.pdfDownload) {
          uni.postMessage({ data: { action: "base64", url: this.$route.fullPath } });
        } else {
          this.isDownload = true
          const { reInquirytorName } = this.formData;
          let title = '询问笔录-' + reInquirytorName + '-' + this.parseTime(new Date().getTime(), '{y}年{m}月{d}日{h}时{i}分');
          this.uploadPdf(false, title);
        }
      }
    },
  }
};
</script>
<style lang="scss">
.module-dialog,
.leftUp-dialog,
.right-dialog {
  &>.el-card {

    &>.el-card__body {
      padding: 0;
      overflow-y: auto;
      height: calc(100vh - 120px);
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep.item-record p {
  margin: 0;
}

.penrecord_wrap {
  .focus-active {
    background: #fbf831;
  }

  .end-line {
    text-align: center;
    color: #bbb;
    padding: 8px 0;
  }

  .leftUp-dialog,
  .right-dialog,
  .left-dialog {
    z-index: 2000;
    width: 400px;
    position: absolute;
    right: 8px;
    top: 0;

    .content {
      font-size: 12px;
      padding: 20px;
      padding-left: 30px;
    }

    .hide-box {
      position: absolute;
      right: 375px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      z-index: 2001;
      font-size: 28px;
    }

    .el-card__body {
      padding: 0;
      overflow-y: auto;
      height: calc(100vh - 120px);
    }

    .item {
      cursor: pointer;
      padding: 3px 3px;
      background-color: #f1f2f3;
      line-height: 26px;
      font-size: 12px;
      color: #000;
      padding-left: 5px;
      box-sizing: border-box;
      margin-bottom: 6px;

      &.question {
        color: blue;
      }

      &.answer {
        color: #333;
      }

      p {
        position: relative;
        padding: 0;
        margin: 0;
      }

      &.active {
        background-color: #74b0ef;
        color: #fff;
      }
    }
  }

  .left-dialog {
    left: 8px;
    padding-bottom: 30px;

    .el-card__body {
      height: calc(100vh - 380px);
    }

    .footer {
      position: absolute;
      bottom: 3px;
      right: 8px;
    }
  }

  .leftUp-dialog {
    left: 8px;
    width: 360px;

    .el-card__body {
      height: 180px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .hide-box {
      left: 330px;
    }
  }

  .video-wrap {
    background: #f1f2f3;

    .time {
      padding: 0 20px;
    }
  }

  .put-away {
    transition: 0.5s;
    right: -370px;
  }

  .content-tit {
    margin: 10px 0;
    margin-top: 30px;
  }
}
</style>
