
import { convertToLabelValueList } from '@/const/utils.js'

export const INQUIRYGO_TYPE = {
    SEIZE: 0, // 被扭送
    VOLUNTARYSURRENDER: 1, // 自动投案
    VERBALSUMMONS: 2, // 口头传唤
}

export const INQUIRYGO_NAMES = {
    [INQUIRYGO_TYPE.SEIZE]: "被扭送",
    [INQUIRYGO_TYPE.VOLUNTARYSURRENDER]: "自动投案",
    [INQUIRYGO_TYPE.VERBALSUMMONS]: "口头传唤",
};

export const INQUIRYGO_LIST = convertToLabelValueList(INQUIRYGO_TYPE, INQUIRYGO_NAMES)

export const INQUIRYSEX_TYPE = {
    MALE: 0,
    FEMALE: 1,
}

export const INQUIRYSEX_NAMES = {
    [INQUIRYSEX_TYPE.MALE]: "男",
    [INQUIRYSEX_TYPE.FEMALE]: "女",
};

export const INQUIRYSEX_LIST = convertToLabelValueList(INQUIRYSEX_TYPE, INQUIRYSEX_NAMES)

export const INQUIRYCARD_TYPE = {
    IDCARD: 0,
    RESIDENCEPERMIT: 1,
}

export const INQUIRYCARD_NAMES = {
    [INQUIRYCARD_TYPE.IDCARD]: "居民身份证",
    [INQUIRYCARD_TYPE.RESIDENCEPERMIT]: "外国人居留证",
};

export const INQUIRYCARD_LIST = convertToLabelValueList(INQUIRYCARD_TYPE, INQUIRYCARD_NAMES)


