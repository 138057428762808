<template>
    <div id="view_warper" ref="pdfDom" :class="{ pdf_download: pdfDownload }" style="page-break-after: always"
        class="view_warper cardBg" @click="classKey = ''">
        <div>
            <p ref="time" :class="editClass(['time'])" class="t-a-r item">
                第 <span>{{ formData.time || "--" }}</span> 次询问
            </p>
            <h1 class="t-a-c">询问笔录</h1>
            <p class="inquiry-time-wrap inquiry-wrap">
                询问时间<span class="content"><span style="flex:1" ref="startTime" :class="editClass(['startTime'])">
                        <FormatInquiryTime v-if="formData.startTime" :date="formData.startTime" />
                    </span>
                    至<span style="flex:1">
                        <FormatInquiryTime v-if="formData.endTime" :date="formData.endTime" />
                    </span></span>
            </p>
            <p class="inquiry-wrap" :class="editClass(['address'])">
                询问地点<span class="content">{{ formatAddress(formData.address) }}</span>
            </p>
            <p class="inquiry-person-wrap inquiry-wrap" :class="editClass(['inquirytorName'])">
                询问人 (签名) <span class="content" ref="inquirytorName">{{ formData.inquirytor }}
                </span> 工作单位<span class="content" ref="inquirytorOrg" :class="editClass(['inquirytorOrg'])">
                    {{ formData.inquirytorOrg }}
                </span>
            </p>
            <p class="inquiry-person-wrap inquiry-wrap" ref="recordtorName" :class="editClass(['recordtorName'])">
                记录人 (签名) <span class="content">{{ formData.recordtor || "" }}</span> 工作单位<span class="content"
                    ref="recordtorOrg" :class="editClass(['recordtorOrg'])"> {{ formData.recordtorOrg || "" }}</span>
            </p>
            <p class="inquiry-date-wrap inquiry-wrap">
                被询问人
                <span class="content" ref="reInquirytorName" :class="editClass(['reInquirytorName'])">{{
                    formData.reInquirytorName || "" }}
                </span>
                性别
                <span class="content" ref="reInquirytorSex" :class="editClass(['reInquirytorSex'])">{{
                    inquirySexNames[formData.reInquirytorSex] || "" }}
                </span>
                年龄
                <span class="content" ref="reInquirytorAge" :class="editClass(['reInquirytorAge'])">{{
                    formData.reInquirytorAge
                    || "" }}
                </span>
                出生日期
                <span class="content" ref="reInquirytorBirth" :class="editClass(['reInquirytorBirth'])">{{
                    parseTime(formData.reInquirytorBirth, "{y}年{m}月{d}日") || ""
                }} </span>
            </p>
            <p class="inquiry-wrap">
                户籍所在地
                <span class="content" ref="reInquirytorAddressOld" :class="editClass(['reInquirytorAddressOld'])">{{
                    formData.reInquirytorAddressOld || ""
                }} </span>
            </p>
            <p class="inquiry-wrap">
                现住址
                <span class="content" ref="reInquirytorAddressNow" :class="editClass(['reInquirytorAddressNow'])">{{
                    formData.reInquirytorAddressNow || ""
                }} </span>
            </p>
            <p class="inquiry-wrap">
                <span>被询问人身份证件种类及号码 </span>
                <span class="content">
                    <span ref="reInquirytorCardType" :class="editClass(['reInquirytorCardType'])">
                        {{ inquiryCardNames[formData.reInquirytorCardType] || "" }} </span>
                    <span ref="reInquirytorCardNum" :class="editClass(['reInquirytorCardNum'])">
                        {{ formData.reInquirytorCardNum || "" }} </span>
                </span>
                <span><i ref="reInquirytorIsNpc" style="font-style: normal" :class="editClass(['reInquirytorIsNpc'])">{{
                    formData.reInquirytorIsNpc === "1" ? "√" : "□" }} 是 {{ formData.reInquirytorIsNpc === "1" ? "□" :
        "√" }}
                        否
                        人大代表</i></span>
            </p>
            <p class="inquiry-wrap">联系方式<span class="content">{{ formData.reInquirytorPhone || "" }}</span></p>
            <p class="item">
                (<span ref="reInquirytorGoType" :class="editClass(['reInquirytorGoType'])">
                    <span>{{ inquiryGoNames[formData.reInquirytorGoType] }}</span>
                </span>的被询问人
                <span style="display: inline-block;line-height: 2.2;">
                    <FormatInquiryTime v-if="formData.reInquirytorStartTime" :date="formData.reInquirytorStartTime"
                        pattern="{m},{d},{h},{i}" />
                </span>
                <span>到达，</span>
                <span style="display: inline-block;line-height: 2.2;">
                    <FormatInquiryTime v-if="formData.reInquirytorEndTime" :date="formData.reInquirytorEndTime"
                        pattern="{m},{d},{h},{i}" />
                </span>
                <span>离开， 本人签名确认</span>
                <img class="content" v-if="formData.reInquirytorSignature" ref="reInquirytorSignature"
                    :class="editClass(['reInquirytorSignature'])" :src="formData.reInquirytorSignature"
                    style="width: 60px;height: 40px;display: inline-block;vertical-align: middle;" alt="" srcset="" />
                <span v-else class="content"
                    style="width: 60px;height: 40px;display: inline-block;vertical-align: middle;"></span>)
            </p>
            <!-- <p ref="content" class="content-tit item">笔录内容:</p> -->
            <!-- <br> -->
            <div class="item item-record" :class="editClass(['content'])" v-html="formData.contentRender || ''">
            </div>

            <!-- <draggable v-if="isSeal" id="draggable" ref="draggable" :width="obj.width" :height="obj.height" :left="obj.left" :top="obj.top">
          <img src="@/assets/seal.png" alt="" srcset="" width="100%" style="height:auto" />
        </draggable> -->
            <!-- <draggable v-if="isSeal" @start="drag=true" @end="drag=false">
          <img src="@/assets/seal.png" alt="" srcset="" width="100%" style="height:auto" />
        </draggable> -->
            <!-- <drag-it-dude v-if="isSeal">
            <img src="https://cxdy-hd-bucket.oss-cn-hangzhou.aliyuncs.com/seal.png" alt="" srcset="" width="150"
                style="height: auto" />
        </drag-it-dude> -->
        </div>
    </div>
</template>

<script>
import { checkType } from '@/utils/index';
import FormatInquiryTime from '@/components/FormatInquiryTime'
import { INQUIRYGO_NAMES, INQUIRYSEX_NAMES, INQUIRYCARD_NAMES } from '@/const/archives/penrecord.js'
export default {
    components: { FormatInquiryTime },
    props: {
        formData: {
            type: Object
        },
        pdfDownload: {
            type: Boolean,
        }
    },
    computed: {
        inquiryGoNames() {
            return INQUIRYGO_NAMES
        },
        inquirySexNames() {
            return INQUIRYSEX_NAMES
        },
        inquiryCardNames() {
            return INQUIRYCARD_NAMES
        },
        editClass() {
            return props => {
                return props.includes(this.classKey) ? 'focus-active' : '';
            };
        },
        formatInquirytorName() {
            return (val, index) => {
                if (!val || !val.length) return '';
                const inquirytor = val.split('|');
                const arr = [];
                inquirytor.forEach(item => {
                    arr.push(item.split('-')[index]);
                });
                if (index === 0) {
                    return arr[0]
                } else {
                    return arr.join(',');
                }
            };
        },
        formatAddress() {
            return address => {
                if (!address || !address.length) return '';
                if (checkType(address) === 'string') {
                    return address.split(',')[2]
                } else if (checkType(address) === 'array') {
                    return address[2];
                }
            };
        },
    }
}
</script>
<style scoped lang="scss">
.view_warper {
    font-size: 17px;
    color: #000;
    overflow-y: auto;

    &.pdf_download {
        padding: 63.5px 79.25px;
        font-size: 22px;
    }

    .seal_box {
        position: absolute;

        img {
            height: auto;
        }
    }

    .inquiry-wrap {
        display: flex;
        line-height: 2.2;

        .content {
            padding-left: 5px;
            flex: 1;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &::after {
                content: '';
                width: 100%;
                display: block;
                border-bottom: 1px solid #000;
                position: absolute;
                bottom: 7px;
            }
        }
    }

    .inquiry-time-wrap {
        .content {
            display: flex;

            &::after {
                border: none;
            }
        }
    }

    .inquiry-person-wrap {
        .content:nth-of-type(2) {
            flex: 2;
        }
    }

    .inquiry-date-wrap {
        .content:nth-of-type(1) {
            flex: 2
        }

        .content:nth-of-type(2) {
            flex: 0 0 25px;
            text-align: center;
        }

        .content:nth-of-type(3) {
            flex: 0 0 25px;
            text-align: center;
        }


        .content:nth-of-type(4) {
            flex: 3
        }

    }



    p,
    pre {
        margin: 0;
        line-height: 23px;

        &.not-underline {
            span {
                text-decoration: none;
            }
        }
    }

    &>p {
        line-height: 34px;
    }

    pre {
        text-decoration: underline;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    img {
        vertical-align: middle;
        height: 25px;
    }
}
</style>