/**
 * 将对象转换为包含"label"和"value"属性的对象数组
 * @param {Object} obj - 要转换的对象
 * @returns {Array} - 转换后的对象数组
 */
export function convertObjectToArrayOfObjects(obj, labelKey, valueKey) {
	return Object.entries(obj).map(([value, label]) => {
		return {
			label: label[labelKey] || label,
			value: value[valueKey] || value,
		}
	});
}


/**
 * 将对象转换为包含"label"和"value"属性的对象数组
 * @param {Object} types - 要转换的对象
 * @param {Object} names - 包含类型名称的对象
 * @returns {Array} - 转换后的对象数组
 */
export function convertToLabelValueList(types, names) {
  return Object.values(types).map(value => ({
    label: names[value],
    value,
  }));
}
